import React from 'react';
import '@google/model-viewer';
import '../App.css'

import model3D_glb from '../assets/papainoel.glb'
import model3D_usdz from '../assets/papainoel.usdz'

function Sapato() {
  return (
    <div className="App">
      <model-viewer
        src={model3D_glb}
        ios-src={model3D_usdz}
        autoplay
        ar
        ar-modes="webxr scene-viewer quick-look"
        camera-controls
        alt="A 3D model of an build make by Dilis"
      >
      </model-viewer>
    </div>
  );
}

export default Sapato;